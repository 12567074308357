<template>
  <div class="login">
    <div class="base">
      <div class="form">
        <!-- <div class="logo"><img src="../assets/img/login/login4.png" ></div> -->
        <div class="table">
          <slot></slot>

          <div class="flex row-between plr25 pt20 f16 c666">
            <div class="cup" @click="goLeave">
              {{ type == 'forget' ? '注册新账号' : '忘记密码' }}
            </div>
            <div class="cup" @click="goPage">
              {{ type == 'login' ? '注册新账号' : '已有账号' }}
            </div>
          </div>
          <div class="party flex row-between pt50">
            <div class="cup"><img src="../assets/img/login/qq.png" /></div>
            <div class="cup" @click="usewxlogin">
              <img src="../assets/img/login/wecat.png" />
            </div>
          </div>
        </div>

        <!-- <div class="monkey"><img src="../assets/img/login/login1.png" ></div>
        <div class="flower"><img src="../assets/img/login/login2.png" ></div>
        <div class="sheep"><img src="../assets/img/login/login3.png" ></div> -->
        <div class="loginBg1">
          <img src="../assets/img/login/loginBg1.png" />
        </div>
        <div class="loginBg2">
          <img src="../assets/img/login/loginBg2.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loginCase',
  props: {
    type: String
  },
  methods: {
    goLeave() {
      if (this.type == 'forget') {
        this.$router.push('/register')
      } else {
        this.$router.push('/forget')
      }
    },
    goPage() {
      if (this.type == 'login') {
        this.$router.push('/register')
      } else {
        this.$router.push('/login')
      }
    },
    // 微信登陆
    usewxlogin() {
      console.log(3232)
      var http = window.location.protocol
      var url = window.location.host
      let appId = 'wx18c87b5258a14bae' //公众号平台提供的appId
      let redirectURI = http + '//' + url + '/login' //扫码后回调地址
      // let redirectURI = 'http://www.wwkid.com/login'
      window.location.href =
        'https://open.weixin.qq.com/connect/qrconnect?appid=' +
        appId +
        '&redirect_uri=' +
        redirectURI +
        '&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect'
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  min-width: 1280px;
  height: 100vh;
  background-color: #000034;
  .base {
    width: 100%;
    min-width: 1280px;
    height: 100vh;
    background-color: #ffffff;
    .form {
      position: relative;
      width: 520px;
      height: 100%;
      margin: 0 auto;
      padding-top: 60px;
      .loginBg1 {
        position: absolute;
        transform: translateX(-50%);
        top: 0;
        margin-left: 50%;
        img {
          width: 560px;
        }
      }
      .loginBg2 {
        position: absolute;
        left: -234px;
        bottom: 0;
      }
      .sheep {
        position: absolute;
        right: -600px;
        bottom: 0;
      }
      .flower {
        position: absolute;
        left: -600px;
        bottom: 10px;
      }
      .monkey {
        position: absolute;
        left: -320px;
        top: 0;
      }
      .table {
        width: 414px;
        margin: 0 auto;
        margin-top: 150px;
        position: relative;
        z-index: 2;
        .party {
          width: 230px;
          margin: 0 auto;
        }
      }
      .logo {
        width: 100%;
      }
    }
  }
}
</style>
